






























import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'

import {
  AvailablePaymentMethod,
  PaymentMethodCode,
  PaymentMethodPayload
} from '../../../../contexts'

import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { Inject, logger } from '../../../../support'
import { Loader } from '../../../shared/molecules/Loader/Loader.vue'
import { StructureConfigurable } from '../../../../support/mixins'

import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'

import { AbstractStep } from '../AbstractStep'
import {
  defaultConfig,
  PAYMENT_STEP_CONFIG_KEY, PaymentMethodValue,
  PRZELEWY24,
  Przelewy24ExcludedMethods
} from './PaymentStep.config'
import { defaultPaymentDriver, paymentsDriversRegistry } from './drivers/drivers'
import { PaymentMethod } from './PaymentStep.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<PaymentStep>({
  name: 'PaymentStep',
  components: { Loader },
  created (): void {
    this.config = this.getComponentConfig(PAYMENT_STEP_CONFIG_KEY, defaultConfig)
    this.loadAvailablePaymentMethods(this.cart.id)
  }
})
export class PaymentStep extends Mixins(AbstractStep, CartAnalyticsMixin, StructureConfigurable) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public errors: string[] = []
  public isLoading: boolean = false

  // public selectedPayment: PaymentMethodPayload | null = this.payload.payment
  public supportedPaymentMethods: string[] | null = null

  public get selectedPayment (): PaymentMethodPayload | null {
    return this.payload.payment
  }

  public set selectedPayment (payment: PaymentMethodPayload | null) {
    this.onChange('payment', payment)
  }

  public callbacks: CheckoutStepCallback[] = []

  public availablePaymentMethods: AvailablePaymentMethod[] = []

  public get selectedPaymentCode (): PaymentMethodCode | string | null {
    if (this.payload.payment && this.payload.payment.code === PRZELEWY24 &&
      this.payload.payment.methodId && Przelewy24ExcludedMethods.includes(Number(this.payload.payment.methodId))) {
      return String(this.payload.payment.methodId)
    }
    return this.payload.payment?.code ?? null
  }

  public get isDisabled (): boolean {
    return !this.isValidStep()
  }

  public getSupportedPaymentMethods (): string[] {
    let methods = this.getConfigProperty<PaymentMethodValue[]>('drivers')
    const availableMethods = this.availablePaymentMethods.map(({ code }) => code)

    methods = methods.filter((method) => {
      if (typeof method === 'string') {
        return availableMethods.includes(method)
      }

      if (typeof method === 'object' && 'code' in method) {
        return availableMethods.includes(method.code)
      }

      return false
    })

    // todo: lirene: zweryfikowac czy to sprawdzenie nie jest potrzebne w Lirene - Aelia ma paczkomat za pobraniem, Lirene nie
    // if (this.payload.shipping && this.payload.shipping.carrierCode === ShippingMethodCode.Paczkomat) {
    //   return methods.filter((m) => m !== PaymentMethodCode.OnDelivery)
    // }

    return methods.map(method => (typeof method === 'string') ? method : method.methodId)
  }

  public get supportedPaymentMethodsIcons (): Record<string, string> {
    return this.getConfigProperty<Record<string, string>>('icons')
  }

  public get methods (): PaymentMethod[] {
    if (!this.supportedPaymentMethods) {
      return []
    }

    const unorderedMethods = this.availablePaymentMethods
      .filter(({ code }) => (this.supportedPaymentMethods ?? []).includes(code))
      .map((method: PaymentMethodPayload<string>) => ({
        id: method.code,
        title: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.title`),
        description: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.description`),
        image: this.supportedPaymentMethodsIcons[method.code],
        driver: method.code in paymentsDriversRegistry ? paymentsDriversRegistry[method.code]
          : defaultPaymentDriver
      }))

    return this.supportedPaymentMethods.map((code) => unorderedMethods.filter((m) => m.id === code)[0])
  }

  public async loadAvailablePaymentMethods (cardId: string) {
    this.isLoading = true

    this.availablePaymentMethods = await this.checkoutService.loadAvailablePaymentMethods(cardId)
    this.supportedPaymentMethods = this.getSupportedPaymentMethods()

    this.isLoading = false
  }

  public onModelUpdate (value: PaymentMethodCode | number): void {
    // todo: maybe refactor: nie powinnismy polegac na tym, ze methodId jest numeryczne
    if (isFinite(Number(value)) && (Przelewy24ExcludedMethods.includes(Number(value)))) {
      const p24Method = this.availablePaymentMethods.find(method => method.code === PRZELEWY24)

      if (!p24Method) {
        this.selectedPayment = null
      } else {
        this.selectedPayment = { ...p24Method, methodId: Number(value) }
      }
    } else {
      this.selectedPayment = this.availablePaymentMethods.find(method => method.code === value) ?? null
    }
    this.$nextTick(this.submit)
  }

  public onSaving (value: boolean): void {
    this.$emit('saving', value)
  }

  public static isValidStep (payload: AnyObject): boolean {
    return payload.payment !== null &&
      typeof payload.payment.code === 'string' && payload.payment.code.length > 0
  }

  public async submit (): Promise<void> {
    if (!this.selectedPayment) {
      return
    }

    if (!this.isValidStep()) {
      return
    }

    this.errors = []

    try {
      this.$emit('saving', true)

      if (this.callbacks.length > 0) {
        let cart
        for (const c of this.callbacks) {
          const returned = await c.callable.apply(c.onThis, c.args)
          if (typeof returned === 'object' && returned !== null) {
            cart = returned
          }
        }

        this.$store.commit(CartMutationTypes.SetCart, cart)
      }

      this.eventBus.emit('app:checkout.addPaymentInfo', {
        ...this.getBaseCheckoutPayload(this.cart),
        shippingTier: this.cart.selectedShippingMethod?.methodCode,
        paymentMethod: this.selectedPayment.code
      })
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.$emit('saving', false)
    }
  }

  public goToNextStep (): void {
    if (!this.payload.payment || this.payload.payment.code.length === 0) {
      this.errors = [this.$t('front.checkout.organisms.PaymentStep.error').toString()]
      return
    }

    this.nextStep()
  }

  protected isValidStep (): boolean {
    return this.selectedPayment !== null && this.selectedPayment.code.length > 0
  }
}

export default PaymentStep
