























import { Component, Watch } from 'vue-property-decorator'

import { logger } from '../../../../../support'

import PaymentBaseDriver from './BaseDriver.vue'
import { Przelewy24ExcludedMethods } from '../PaymentStep.config'
import { Przelewy24PaymentMethodDefinition } from '../PaymentStep.contracts'

/**
 * Driver for `przelewy24`.
 *
 * @summary - This driver loads all available payment methods in P24
 *            and allows you to select one.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Przelewy24General>({
  name: 'Przelewy24General',
  created (): void {
    this.loadPaymentsMethods()
  }
})
export class Przelewy24General extends PaymentBaseDriver {
  public paymentMethods: Przelewy24PaymentMethodDefinition[] = []
  public selectedPaymentMethodId: number | null = this.cart.selectedPaymentMethodId ?? null
  public requestChangeSelected: boolean = false

  /**
   * Determines possible payments methods that should be available.
   */
  public get possiblePaymentMethods () {
    return this.paymentMethods.filter((method: any) => {
      return !Przelewy24ExcludedMethods.includes(method.id)
    })
  }

  public get methodImage (): string | undefined {
    if (this.selected !== this.method.id) {
      return this.method.image
    }

    const method = this.paymentMethods.find(m => m.id === this.selectedPaymentMethodId)

    return method ? method.imgUrl : this.method.image
  }

  public changeSelected (): void {
    this.requestChangeSelected = true
  }

  public selectMethod (methodId: number): void {
    this.selectedPaymentMethodId = methodId
    this.requestChangeSelected = false
  }

  /**
   * @override
   */
  protected selectCandidate (): void {
    // this.loadPaymentsMethods()
  }

  private async loadPaymentsMethods (): Promise<void> {
    try {
      this.$emit('saving', true)
      this.paymentMethods = await this.checkoutService.loadPaymentMethods(this.cart.total.value)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

 @Watch('selectedPaymentMethodId')
  private onSelectMethod (): void {
    this.setPaymentMethod([this.cart.id, { code: this.method.id }], {
      type: this.method.id,
      data: {
        method: this.selectedPaymentMethodId,
        regulationAccept: false
      }
    })
  }
}
export default Przelewy24General
